@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.container {
  padding: 40px;
  text-align: center;
  font-family: "Lato", sans-serif;
  font-weight: 300;
  font-style: normal;
  background: rgb(255, 248, 238);
}

.section {
  display: flex;
  justify-content: space-around;
  padding: 40px 0;
  border-bottom: 1px solid rgb(40, 93, 117);
  animation: fadeIn 0.8s ease-in-out;
}

.logo {
  width: 300px;
}

header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 80vh;
  gap: 40px;
}

header h2 {
  font-family: "Lato", sans-serif;
  font-weight: bold;
  font-size: 70px;
  color: #2e3a4a;
}

header img {
  width: 230px;
}
header {
  font-size: 2rem;
}
header h1 {
  font-size: 80px;
  color: #0f303f;
}

.countdown {
  margin-top: 40px;
  font-family: "Lato", sans-serif;
  font-size: 3.2rem;
  font-weight: bold;
  color: #0f303f;
  transition: transform 0.5s ease;
  border-left: 4px solid #0f303f;
  background-image: linear-gradient(
    270deg,
    rgb(255, 248, 238) 0%,
    rgb(142, 201, 202) 160%
  );
  width: 40%;
  margin: 0 auto;
  letter-spacing: 15px;
}

.countdown:hover {
  transform: scale(1.1);
}

.textCont {
  font-family: "Lato", sans-serif;
  font-size: 25px;
  color: #0f303f;
  font-weight: 400;
  margin: 40px 0;
  width: 70%;
  margin: 0 auto;
  display: block;
}

.both {
  width: 100%;
  padding: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.head {
  display: flex;
  flex-direction: column;
}

.signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 45%;
  height: 100%;
  padding: 20px;
  border-radius: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background: linear-gradient(135deg, rgb(142, 201, 202), rgb(40, 93, 117));
}

.signup h1 {
  color: "white";
  margin-bottom: -10px;
  font-size: 45px;
}

.signup form label {
  /* visibility: hidden; */
  color: "white";
  padding: 15px 0 0 15px;
}

.signup form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /* color: rgb(40, 93, 117); */
  color: rgb(255, 248, 238);
  font-weight: bold;
}

.form-group {
  margin-bottom: 15px;
  text-align: left;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  height: 50px;
  padding: 18px;
  border: 1px solid rgb(255, 248, 238);
  border-radius: 30px;
  transition: box-shadow 0.3s ease;
  background: #ffffff00;
}

.form-group input::placeholder {
  color: rgb(255, 248, 238);
  font-weight: bold;
}
.section-2 .form-group input::placeholder {
  color: #0f303f;
}

.form-group textarea::placeholder {
  color: rgb(255, 248, 238);
  font-weight: bold;
}

.form-group textarea {
  width: 100%;
  height: 100px;
  padding: 14px 18px;
  border: 1px solid rgb(255, 248, 238);
  border-radius: 30px;
  transition: box-shadow 0.3s ease;
  background-color: #ffffff00;
  font-size: 14px;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: rgb(142, 201, 202);
  outline: none;
  box-shadow: 0px 0px 5px rgb(142, 201, 202);
}

/* Button Styling */
button {
  padding: 12px 24px;
  background-color: #0f303f;
  color: rgb(255, 248, 238);
  font-size: 1rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.btn1 {
  width: 30%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.btn2:hover,
.btn1:hover {
  background-color: rgb(40, 93, 117);
  transform: scale(1.05);
}

button:after {
  content: " ";
}

button.loading {
  position: relative;
  pointer-events: none;
}

button.loading:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 16px;
  height: 16px;
  border: 2px solid rgb(255, 248, 238);
  border-top: 2px solid transparent;
  border-radius: 50%;
  animation: spin 0.6s linear infinite;
  transform: translate(-50%, -50%);
}

.signup:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

/* Keyframes for smooth animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.form-group {
  width: 48%;
  margin-bottom: 15px;
}

.form-group.full-width {
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

button {
  width: 100%;
  padding: 12px 24px;
  background-color: #0f303f;
  color: rgb(255, 248, 238);
  font-size: 1rem;
  border-radius: 25px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.newsletter {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.section-2 {
  /* height: 100%; */
  padding: 40px;
  text-align: center;
  flex-direction: column;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
  align-items: center;
}

.section-2 h2 {
  font-size: 2rem;
  color: #0f303f;
  margin-bottom: -15px;
  width: 100%;
  max-width: 100%;
}

.section-2 form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  /*width: 100%;*/
  /*max-width: 100%;*/
}

.section-2 .form-group {
  /*width: 100%;*/
  /*max-width: 400px;*/
  margin-bottom: 20px;
  position: relative;
}

.section-2 .form-group input {
  width: 100%;
  height: 50px;
  padding: 18px;
  border: 1px solid #0f303f;
  border-radius: 30px;
  transition: box-shadow 0.3s ease;
  font-size: 14px;
}
.section-2 .form-group textarea {
  width: 100%;
  height: 100px;
  padding: 14px 18px;
  border: 1px solid #0f303f;
  border-radius: 30px;
  transition: box-shadow 0.3s ease;
  background-color: #ffffff00;
  font-size: 14px;
}

.section-2 .form-group textarea::placeholder {
  color: rgb(0, 0, 0);
  font-weight: 100;
}

.section-2 .form-group input:focus,
.section-2 .form-group textarea:focus {
  border-color: rgb(0, 0, 0);
  outline: none;
  box-shadow: 0px rgb(0, 0, 0);
}

.section-2 button {
  width: 30%;
  margin: 0 auto;
  padding: 12px 24px;
  background-color: #0f303f;
  color: rgb(255, 248, 238);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  /*width: 100%;
  max-width: 400px;*/
  display: flex;
  justify-content: center;
}

.right-section {
  opacity: 0; /* Initially hidden */
  transition: opacity 0.5s ease; /* Smooth transition */
}

.left-section.visible {
  animation: slideInLeft 0.5s forwards; /* Apply the slide-in-left animation */
}

.right-section.visible {
  animation: slideInRight 0.5s forwards; /* Apply the slide-in-right animation */
}

.ppp {
  font-size: 15px;
  font-weight: bold;
}

.footer {
  gap: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: baseline;
  background-color: #0f303f;
  padding: 5% 3%;
  border-radius: 30px;
}
.footer div {
  width: 100%;
  max-width: 100%;
}

.soc_icons {
  color: rgb(255, 248, 238);
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  gap: 30px;
}

.soc_icons i:hover {
  scale: 1.2;
}

.footer div h2,
.footer div p,
.footer div h3 {
  color: rgb(255, 248, 238);
}
.footer div p {
  font-size: 18px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .logo {
    width: 230px;
  }

  .textCont {
    font-size: 18px !important;
  }

  .form-group {
    width: 100%;
  }

  .both {
    flex-direction: column;
    padding: 0;
  }

  .signup {
    width: 95%;
    margin-bottom: 40px;
  }

  .newsletter {
    width: 95%;
  }

  .countdown {
    width: 80%;
    margin: 0 auto;
    font-size: 35px;
  }
}

@media (max-width: 480px) {
  .textCont {
    font-size: 19px !important;
    width: 100%;
  }

  .signup {
    width: 100%;
    margin-bottom: 30px;
  }

  .newsletter {
    width: 100%;
    gap: 20px;
  }

  .countdown {
    min-width: 70%;
    padding: 10px;
    font-size: 1rem;
  }

  .section-2 h2 {
    font-size: 32px;
  }

  header {
    gap: 20px;
  }
  header h2 {
    font-size: 40px;
  }

  .footer {
    margin-top: 30px;
    padding: 5% 8%;
  }
}
